import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GridFilter extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  isShow;

  filterVisible = false;

  created(): void {
    this.filterVisible = this.isShow;
  }

  mounted(): void {
    document.addEventListener('keypress', this.keypressEventListener)
  }

  beforeDestroy() {
    document.removeEventListener('keypress', this.keypressEventListener);
  }

  applyFilter() {
    this.$emit('applyFilter');
  }

  resetFilter() {
    this.$emit('resetFilter');
  }

  keypressEventListener(event) {
    if (event.key === 'Enter') {
      this.applyFilter();
    }
  }
}
