import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'PopoverPicker',
})
export default class PopoverPicker extends Vue {
  selectedCount = 0;

  @Prop({
    required: false
  })
  placeholder?: string;

  @Prop({
    required: false
  })
  count?: number;

  @Watch('count')
  onChangeCount(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.selectedCount = newVal;
    }
  }

  created() {
    this.selectedCount = this.count || 0;
  }

  get selectedTitle() {
    return this.selectedCount ? `${this.selectedCount} ${this.$t('selected')}` : '';
  }
}
