import { Component, Vue } from 'vue-property-decorator';
import { IHeaderParams } from 'ag-grid-community';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';

@Component({
  components: {
    VisibleIcon
  }
})
export default class VisibleHeader extends Vue {
  params!: IHeaderParams;

  get headerId() {
    return `ag-header-${this.params.column.getColId()}`;
  }
}
