import debounce from 'lodash/debounce';
import appConfig from '@/app.config.json';

export const debounceFilter = (filterFunc: (...args: any) => any) => debounce(filterFunc, Number(appConfig.debounceFilter));

export const setEmptyToNull = (filter: Object) => {
  for (const [attrName, value] of Object.entries(filter)) {
    if (value === '') {
      filter[attrName] = null as any;
    }
  }

  return filter;
};

export const hasFilter = (filter: Object) => Object.values(filter).some(item => item);
