import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import Box from '@/components/ui/box/box.vue';
import BoxHeader from '@/components/ui/box/box-header/boxHeader.vue';
import GridFilter from '@/components/ui/grid-filter/gridFilter.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { Action, Getter } from 'vuex-class';
import { ListResponseType } from '@/types/response/list-response.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import ShowLocationGoogleMap from '@/components/ui/modals/showLocationGoogleMap.vue';
import { SpotType } from '@/types/api/spot/spot.type';
import { SpotRequest } from '@/types/request/spot-request.types';
import { debounceFilter, hasFilter, setEmptyToNull } from '@/helpers/filter.helper';
import ShowContent from '@/components/ui/modals/showContent.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import visibleHeader from '@/components/ui/ag-grid/header-components/visible-header/visibleHeader.vue';
import { LanguageType } from '@/types/api/info/language.type';
import PopoverPicker from '@/components/ui/inputs/popover-picker/popover-picker.vue';
import { getAppTypeLabel, getAppTypeList } from '@/constants/app.constants';

const title = 'spot.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    AgGridVue,
    Box,
    BoxHeader,
    GridFilter,
    PopoverPicker
  },
})
export default class Spots extends Vue {
  title = title;
  agGrid: AgGridHelper | null = null;
  filter: any = this.initFilter();
  flagFilter = null;
  selectedFlagsCount = 0;

  @Action('getSpots', { namespace: 'spot' })
  getSpots!: (params: SpotRequest) => Promise<ListResponseType<SpotType>>;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getSpots({
      take: general.limit,
      skip: general.offset,
      ...setEmptyToNull(this.filter),
      ...(this.filter.app ? { app: [this.filter.app] } : {})
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 100,
      headerHeight: 39,
      suppressHorizontalScroll: true,
    }
  }

  initColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('spot.nameAddress'),
        field: 'name',
        headerComponentFramework: visibleHeader,
        width: 400,
        minWidth: 400,
        cellRenderer: 'nameAddressCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data && params.data.images?.[0]?.url,
          text: (params: ICellRendererParams) => params.data && params.data.name,
          url: (params: ICellRendererParams) => params.data ? `/spot/update/${params.data.id}` : '#',
          geopoint: (params: ICellRendererParams) => params.data && params.data.geopoint,
        }
      },
      {
        headerValueGetter: () => this.$t('spot.activities'),
        field: 'spotActivities',
        headerComponentFramework: visibleHeader,
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        cellRenderer: 'activitiesCellRenderer',
        cellRendererParams: {
          activities: (params: ICellRendererParams) => params.data && params.data.spotActivities,
        },
        cellClass: 'ag-cell-w-100'
      },
      {
        headerValueGetter: () => this.$t('common.flags'),
        field: 'flags',
        headerComponentFramework: visibleHeader,
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: 'badgesCellRenderer',
        cellRendererParams: {
          items: (params: ICellRendererParams) => params.data && [
            { label: this.$t('spot.isBlocked'), active: params.data.isBlocked },
            { label: this.$t('spot.isOnline'), active: params.data.isOnline },
            { label: this.$t('spot.isVIP'), active: params.data.isVIP },
            { label: this.$t('spot.isJunketAllow'), active: params.data.isJunketAllow },
            { label: this.$t('spot.featured'), active: params.data.featured },
          ],
        },
      },
      {
        headerValueGetter: () => this.$t('spot.locales'),
        field: 'locales',
        headerComponentFramework: visibleHeader,
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: 'localesCellRenderer',
        cellRendererParams: {
          locales: (params: ICellRendererParams) => (params.data && params.data.locales) || [],
        }
      },
      {
        headerValueGetter: () => this.$t('spot.appType'),
        field: 'appMeta',
        width: 250,
        minWidth: 250,
        maxWidth: 250,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            return params.data.appMeta.map(item => `<div class="badge mr-1 badge-light">${getAppTypeLabel(item)}</div>`).join('')
          }
          return '';
        },
      },
    ];
  }

  debounceApplyFilter() {
    this.applyFilter();
  }

  applyFilter() {
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  initFilter() {
    return {
      activity: null,
      name: null,
      languageId: null,
      isOnline: null,
      isVIP: null,
      isJunketAllow: null,
      featured: null,
      isBlocked: null,
      app: null,
    };
  }

  get isFilter() {
    return hasFilter(this.filter);
  }

  showLocation(params: ICellRendererParams) {
    this.$modal.show(ShowLocationGoogleMap, {
      lat: params.data.geopoint.coordinates[1],
      lng: params.data.geopoint.coordinates[0],
      address: params.data.address
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  showContent(title: string, content: string) {
    this.$modal.show(ShowContent, { content, title, }, MODAL_CONFIG);
  }

  get languageOptions() {
    return [
      { value: null, text: this.$t('activity.anyLocales') },
      ...(this.languages || []).map(item => ({ value: item.id, text: item.code.toUpperCase() }))
    ];
  }

  onChangeFlag(value: boolean) {
    this.selectedFlagsCount += value ? 1 : -1;
    this.applyFilter();
  }

  get appTypeOptions() {
    return [
      { value: null, text: this.$t('events.anyType') },
      ...getAppTypeList()
    ];
  }
}
